<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">报名咨询（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">报名咨询列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改"
            }}报名咨询机构</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="机构名称：" prop="compName">
                  <el-input
                    v-model="ruleForm.compName"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="培训专业：" prop="trainingContent">
                  <el-input
                    v-model="ruleForm.trainingContent"
                    size="small"
                    placeholder="请输入培训专业，多个专业请用英文逗号分隔"
                    show-word-limit
                  ></el-input>
                </el-form-item>
                <el-form-item label="机构Logo：" prop="logoKeyUrl">
                  <el-upload
                    :on-change="handleAvatarSuccess2"
                    :before-upload="$beforeAvatarUpload"
                    :http-request="$requestMine"
                    :show-file-list="false"
                    class="img-el-upload"
                    action
                    accept="image/png, image/gif, image/jpg, image/jpeg"
                  >
                    <el-image
                      :src="
                        ruleForm.logoKeyUrl || require('@/assets/develop.png')
                      "
                      fit="contain"
                      class="imgCenter"
                    ></el-image>
                  </el-upload>
                </el-form-item>
                <el-form-item label=" " class="form-item form-item-hint">
                  <p>建议上传尺寸640*365。</p>
                </el-form-item>
                <el-form-item
                  label="机构简介："
                  prop="introduction"
                  class="form-item"
                >
                  <div
                    ref="editor"
                    class="editor"
                    style="width:100%;max-width:1200px"
                  />
                </el-form-item>
                <el-form-item label="关联单位：" prop="compId">
                  <el-select
                    size="small"
                    v-model="ruleForm.compId"
                    remote
                    :disabled="stu === 'edit'"
                    :remote-method="getCompanyList"
                    filterable
                    clearable
                    placeholder="请至少输入两个字搜索"
                  >
                    <el-option
                      v-for="item in CompanyList"
                      :key="item.compId"
                      :label="item.compName"
                      :value="item.compId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
              <el-button
                class="bgc-bv"
                @click="doAddSave('ruleForm');
                "
                >保存
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <MenuStyle ref="MenuStyle" @eventBus="styleBack" /> -->
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
let updataTimer = null;
export default {
  name: "operate/accountEdit",
  components: {},
  data() {
    return {
      stu: "add",
      // 基本信息
      ruleForm: {
        compName: "",
        trainingContent: "",
        logoKey: "",
        logoKeyUrl: "",
        context: "", // 富文本
        compId: "",
      },
      rules: {
        compName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
        trainingContent: [
          { required: true, message: "请输入培训专业", trigger: "blur" },
        ],
        logoKeyUrl: [
          { required: true, message: "请上传机构logo图片", trigger: "change" },
        ],
        context: [
          { required: true, message: "请输入机构简介", trigger: "change" },
        ],
        compId: [
          { required: true, message: "请选择关联单位", trigger: "change" },
        ],
      },
      CompanyList: [],
    };
  },
  created() {
    this.stu = this.$route.query.stu;;
    if (this.stu == "edit") {
      this.ruleForm.compId = this.$route.query.compId;
      this.getDetail(this.ruleForm.compId);
    }
  },
  mounted() {
    this.editorInit();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.ruleForm.introduction = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },

    /**
     * 新增
     */
    doAddSave(formName) {
      console.log(this.ruleForm.logoKeyUrl);
      const params = {
        compName: this.ruleForm.compName,
        trainingContent: this.ruleForm.trainingContent,
        logoKey: this.ruleForm.logoKey,
        introduction: this.ruleForm.introduction,
        compId: this.ruleForm.compId
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit" ? "/miniapp/company/insert" : "/miniapp/company/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                }
                this.dohandleOk();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * 编辑回显
     */
    getDetail(compId) {
      this.$post(
        "/miniapp/company/getInfo",
        { compId },
        3000,
        true,
        2
      ).then((ret) => {
        this.ruleForm = {
          ...ret.data
        };
        editor.txt.html(ret.data.introduction);
        this.getCompany(ret.data.compId);
        }
      );
    },

    /* 课程封面图 */
    //图片上传
    handleAvatarSuccess2(res) {
      let extension = res.name.substring(res.name.lastIndexOf(".") + 1);
      let formData = new FormData();
      formData.append("folder ", "COURSE_WARE");
      formData.append("file ", res.raw);
      formData.append("fileType ", extension);
      formData.append("isPublic ", true);
      const isLt2M = res.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      } else if (
        ["image/png", "image/gif", "image/jpg", "image/jpeg"].indexOf(
          res.raw.type
        ) === -1
      ) {
        this.$message.error("请上传正确的图片格式");
        return false;
      } else {
        this.$Postformat("/sys/upload", formData)
          .then((result) => {
            this.ruleForm.logoKeyUrl = result.data.fileURL || "";
            this.ruleForm.logoKey = result.data.fileKey || "";
          })
          .catch(() => {
            setTimeout(() => {
              this.$message({
                type: "warning",
                message: "上传失败",
              });
            }, 300);
          });
      }
    },
    // 关联单位
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/miniApp/registrationConsultationList",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
  },
  beforeDestroy: function() {
    if (updataTimer) {
      clearTimeout(updataTimer);
    }
  },
};
</script>
<style lang="less" scoped>
.andDiscountPrice {
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
.price {
  display: flex;
  //   width: 45%;
  div {
    display: flex;
    span {
      text-align: right;
      margin-right: 0.25rem;
    }
  }
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
    padding-left: 15px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    .el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      display: flex;
      align-items: center;
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}

.btnbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.courseDescription {
  /deep/.el-textarea__inner {
    min-height: 120px !important;
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>
